import React, {useEffect, useState} from "react";
import {firestore} from "../../firebase/clientApp";
import {Box, Container, Stack, useTheme} from "@mui/material";
import {Textfit} from "react-textfit";
import {QuestionSwitch} from "./QuestionSwitch";
import pleshDark from "../../theme/pleshDark";

function QuestionBar({idPlaying, tipo, domanda, colore}) {
    const theme = useTheme()
    return (
        <Container sx={{height: '25%'}}>
            <Box sx={{height: '3rem'}}></Box>
            <Box sx={{position: 'relative', width: '100%', height:'100%'}}>
                {idPlaying === '7isDEyLsUMHpzpSnO9KP' &&
                    <Textfit mode={'multi'} min={12} max={15}
                             style={{
                                 color: theme.palette.text,
                                 fontWeight: 'normal',
                                 fontFamily: 'Proto Grotesk',
                                 width: '100%',
                                 textAlign: 'left',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'center'
                             }}>
                        25 anni fa nasceva Fineco, un anno dopo il motore di ricerca più usato al mondo Google. L’emoji più ricercata di sempre è ❤️.
                    </Textfit>
                }
                <Box sx={{height: '1rem'}}></Box>
                <Textfit mode={'multi'} min={12} max={20}
                         style={{
                             color: tipo === 'color' ? (theme.palette.getContrastText(colore || pleshDark.palette.background.paper)) : theme.palette.text,
                             fontWeight: 'bold',
                             fontFamily: 'Proto Grotesk',
                             width: '100%',
                             height: 'fit-content',
                             textAlign: 'left',
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center'
                         }}>
                    {domanda}
                </Textfit>
            </Box>
        </Container>
    )
}

function QuestionContent({elementPlaying, theme}) {
    return(
        <Container sx={{height:'75%', position:'relative', paddingTop:'20px', textAlign:'center'}}>
            <QuestionSwitch elementPlaying={elementPlaying} theme={theme}/>
        </Container>
    )
}


export default function QuestionDialogContent({url, user,idPlaying,open, theme}) {
    const [elementPlaying, setElementPlaying] = useState({})

    useEffect(()=>{
        idPlaying && getElementPlaying()
    }, [idPlaying])

    function getElementPlaying() {
        firestore.collection(url+'/queue').doc(idPlaying).onSnapshot(s => {
            setElementPlaying({...s.data(), id: s.id})
            return s.data()
        })
    }
    return(
        <Stack id={'questionDialogBg'} justifyContent={"space-between"} style={{height:'100%', zIndex:1}}>
            <QuestionBar idPlaying={idPlaying} tipo={elementPlaying.tipo} colore={elementPlaying.colore} domanda={elementPlaying.domanda}/>
            <QuestionContent elementPlaying={elementPlaying} user={user} theme={theme}/>
        </Stack>
    )
}

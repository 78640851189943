import React, {useEffect, useState} from "react";
import Applausometro from "./Applausometro";
import Question from "./Questions/Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {Box, CircularProgress, Container, Grow, Stack, useTheme} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import CountdownTimerWrapper from "./CountdownTimer";
import {useMediaQuery} from "@material-ui/core";

const LogoBox = ({logo}) => {
    return(
        <Box style={{width:'100%', height:'30%'}} sx={{px: 2}}>
            <img style={{ margin:'auto', width:'100%', height:'100%', objectFit:'contain'}} src={logo}/>
        </Box>
    )
}

export default function VoteContent({user, event, loading, userData, setUserData}) {
    const themeResponsive = useTheme();
    const isMobile = useMediaQuery(themeResponsive.breakpoints.down('sm'));

    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==",true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [userData]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box>
            <Stack direction={"column"} justifyContent={"start"} alignContent={"center"} sx={{height: '75vh', py: 0}}>
                <a href="https://fineco-24-preview.winston-form.pages.dev/home" target="_blank" rel="noopener noreferrer">
                    <img src={'/home-icon.svg'}
                         style={isMobile ?
                             {position: 'absolute', top: '2vh', right: '2vh', width: '40px', zIndex:'10'}
                             :
                             {position: 'absolute', top: '2vh', right: '21vw', width: '40px', zIndex:'10'}
                    }
                         alt="Home Icon"
                    />
                </a>
                <Container sx={{
                    backgroundImage: 'url("/images/bkg-clouds.png")',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition:'center',
                    minHeight: '25vh',
                    width:'100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    position: 'relative'
                }}>
                    <img src={'/images/header-2.png'}
                         style={isMobile ? {marginBottom: '1vh', width: '30vw', marginLeft: '-1vw'} : {
                             marginBottom: '1vh',
                             width: '10vw',
                             marginLeft: '-0.5vw'
                         }}
                         alt="Back Arrow"/>
                    <img src={'/images/header-1.png'}
                         style={isMobile ? {marginBottom: '1vh', width: '80vw'} : {marginBottom: '1vh', width: '20vw'}}
                         alt="Back Arrow"/>
                </Container>
                <Box sx={{height: '4rem'}}></Box>
                {/*{event.data().logo && <LogoBox
                    logo={event.data().logoUrl ? event.data().logoUrl : 'https://storage.googleapis.com/2ndstage-public/logos/logo-2nd.png'}/>}*/}
                {event?.data()?.timer && !!event?.data()?.timer?.startedAt &&
                    <Grow in timeout={2000}>
                        <Box display={'flex'} justifyContent={'center'}>
                            <CountdownTimerWrapper label={event.data().timer.label}
                                                   initialTime={event.data().timer.duration}
                                                   isLive={event.data().timer.startedAt}
                                                   startedAt={event.data().timer.startedAt}/>

                        </Box>
                    </Grow>}
                {
                    event.data().autenticazione &&
                    <DisplayName userData={userData} setUserData={setUserData}/>
                }
                {!pinnedLoading && !pinnedError && pinned ?
                    event.data().autenticazione
                        ?
                        (userData.displayName !== '' && userData.displayName) &&
                        <PinnedQuestions questions={pinned}/>
                        :
                        <PinnedQuestions questions={pinned}/>
                    :
                    <></>
                }
            </Stack>
            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                event.data().applausometro ?
                    event.data().autenticazione
                    ?
                    (userData.displayName !== '' && userData.displayName) &&
                        <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                    :
                    <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                :
                    <></>
            }
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (userData.displayName !== '' && userData.displayName) &&
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}
